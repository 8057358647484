.navbar-custom-bg {
  background-color: #fed9ed;
}

.custom-nav-link {
  color: #63496c;
  position: relative;
  text-decoration: none;
}

.navbar-brand,
.custom-brand {
  margin: 0;
  padding: 0;
}

.custom-dropdown-icons {
  color: #45324b;
}

.custom-dropdown-icons:hover {
  color: #fed9ed;
  background-color: #63496c;
}

.custom-nav-link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: #8c6699;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.custom-nav-link:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

@media (max-width: 480px) {
  .logo-animation {
    width: 10px;
    margin: 0;
    padding: 0;
  }
}
