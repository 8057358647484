.images-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.images {
  width: 33%;
  margin: 1em;
}

.custom-shadow .shadow {
  transition: 0.5s ease;
}

.custom-color {
  background-color: #fed9ed;
}

.custom-shadow .shadow:hover {
  box-shadow: 1px 1px #373737, 2px 2px #373737, 3px 3px #373737, 4px 4px #373737,
    5px 5px #373737, 6px 6px #373737;
  -webkit-transform: translateX(-3px);
  transform: translateX(-3px);
  transition: 0.5s ease;
}

@media (max-width: 768px) {
  .images-container {
    flex-direction: column;
    align-items: center;
  }

  .images {
    width: 80%;
    margin-bottom: 1em;
  }
}
