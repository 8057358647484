@import url("https://fonts.googleapis.com/css2?family=Allura&display=swap");

footer {
  background-color: #fed9ed;
}

h5 {
  font-family: 'Allura', cursive;
  font-size: 30px;
  font-weight: lighter;
}
