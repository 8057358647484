.tours {
  text-align: center;
  margin-bottom: 5em;
}

.tours h1 {
  font-size: 3rem;
}

.tour-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 6em;
}

.tour-text {
  width: 45%;
  text-align: start;
  font-size: 1.1rem;
}

.tour-text h2 {
  padding-bottom: 1rem;
}

.custom-shadow img {
  transition: 0.5s ease;
}

.custom-shadow img:hover {
  box-shadow: 1px 1px #373737, 2px 2px #373737, 3px 3px #373737, 4px 4px #373737,
    5px 5px #373737, 6px 6px #373737;
  -webkit-transform: translateX(-3px);
  transform: translateX(-3px);
  transition: 0.5s ease;
}

.image-container {
  width: 50%;
  display: flex;
  position: relative;
  flex-wrap: wrap;
}

.image-container img {
  width: 45%;
  height: 350px;
  object-fit: cover;
  border-radius: 6px;
  box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
}

.image-container img:nth-child(2) {
  top: -39px;
  position: absolute;
  right: 0;
}

@media (max-width: 768px) {
  .tours {
    margin: 3rem;
  }

  .tour-container {
    flex-direction: column;
    margin-top: 2.5rem;
  }

  .tour-text {
    width: 100%;
  }

  .image-container {
    width: 100%;
    justify-content: center;
  }

  .image-container img {
    width: 90%;
    margin-bottom: 1rem;
  }

  .image-container img:nth-child(2) {
    position: static;
    margin-top: 1rem;
  }
}

@media (max-width: 480px) {
  .tours {
    margin: 2rem;
  }

  .tour-container {
    margin-top: 1.5rem;
  }

  .tour-text {
    font-size: 1rem;
  }

  .image-container img {
    height: auto;
  }
}
