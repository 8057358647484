@font-face {
  font-family: "Igra Sans";
  src: url("./assets/IgraSans.otf") format("opentype");
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
  background-color: rgb(225, 232, 238);
}

h1,
h2,
h3,
h4 {
  font-family: "Igra Sans", sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

.carousel-caption-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.carousel-caption-content h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.carousel-caption-content p {
  font-size: 1.2em;
  color: #ffffff;
}
