.carousel-indicators [data-bs-target] {
  border-radius: 100%;
  width: 10px;
  height: 10px;
}

.carousel-caption {
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
}
.overlay {
  position: relative;
}

.overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: white;
  border-radius: 50%;
  border-color: white;
}

img {
  object-fit: cover;
}
