.custom-button {
	background-color: #4c3254;
	border-color: #63496c;
	color: #ffffff;
	transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.custom-button:hover {
	background-color: #5e3f68;
	border-color: #fed9ed;
	color: #ffffff;
}
